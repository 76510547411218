import { SignIn } from "@clerk/nextjs"
import { Box, Flex } from "@mantine/core"
import { PAGE_BACKGROUND_COLOR } from "@soar/frontend/components"
import { useRouter } from "next/router"
import { P, match } from "ts-pattern"
import { AppFooter } from "../../components/Footer"

export default function LoginPage() {
  const router = useRouter()
  const redirectUrl = match(router.query.redirectUrl)
    .with(P.string, (matchedQuery) => matchedQuery)
    .otherwise(() => "/")
  return (
    <Flex align="center" justify="center" h="100%" bg={PAGE_BACKGROUND_COLOR} direction="column">
      <Box>
        <SignIn afterSignInUrl={redirectUrl} afterSignUpUrl={redirectUrl} signUpUrl="/register" />
        <Box w="100%" mt="lg">
          <AppFooter />
        </Box>
      </Box>
    </Flex>
  )
}
